<template>
    <v-dialog
        v-model="dialog"
        width="450"
        transition="fab-transition"
    >
        <v-card v-if="sale" class="pl-0 pr-0">
            <v-card-title>
                <span class="description-selected">
                    ANULACIÓN DE {{ title }}
                </span> 
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text class="pb-0">
                <v-container>
                    <v-row v-if="viewOptionCreditNote()">
                        <v-col cols="12">
                            <v-switch 
                                v-model="form.generate_credit_note" 
                                class="mt-0" 
                                label="Generar Nota De Crédito"
                                hide-details
                            ></v-switch>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="6">
                            <v-text-field
                                v-model="sale.serie"
                                label="Serie"
                                outlined
                                hide-details
                                readonly
                            >
                            </v-text-field>
                        </v-col>
                        <v-col cols="6">
                            <v-text-field
                                v-model="sale.correlative"
                                label="Número"
                                outlined
                                hide-details
                                readonly
                            >
                            </v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12">
                            <v-textarea
                                ref="reason"
                                v-model="form.reason"
                                label="Motivo de anulación"
                                outlined
                                :rules="[() => !!form.reason || 'Es necesario ingresar el motivo']"
                                hide-details="auto"
                                required
                            >
                            </v-textarea>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>
            <v-card-actions>
                <v-container class="pt-0 pb-0">
                    <v-row>
                        <v-col cols="6">
                            <v-btn dark color="red" block @click="handleCancel">
                                Cancelar
                            </v-btn>
                        </v-col>
                        <v-col cols="6">
                            <v-btn dark color="green" block @click="handleCancelInvoice">
                                Aceptar
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-container>                    
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import {mapActions, mapState} from 'vuex';

export default {
    name: 'CancelInvoice',
    data () {
        return {
            dialog: false,
            title: null,
            sale: {},
            form: {
                generate_credit_note: false,
                sale_id: null,
                reason: null
            }
        }
    },
    computed: {
        ...mapState('authentication', [
            'company_id'
        ]),
        ...mapState('report-sales', [
            'filters'
        ])
    },
    methods: {
        ...mapActions('sales', [
            'storeCreditNote'
        ]),
        ...mapActions('report-sales', [
            'getSales',
        ]),
        show (sale) {
            this.clearForm();
            this.sale = sale;
            this.title = sale.type_document;
            this.dialog = !this.dialog;
        },
        viewOptionCreditNote () {
            let view = false;
            if (this.sale.type_document == 'BOLETA' || this.sale.type_document == 'FACTURA') {
                view = true;
            }

            return view;
        },
        clearForm () {
            this.form.generate_credit_note = false;
            this.form.sale_id = null;
            this.form.reason = null;
            this.clearErrors();
        },
        closeForm() {
            this.clearForm();
            this.dialog = false;
        },
        handleCancel () {
            this.closeForm();
        },
        async handleCancelInvoice () {
            if (!this.existErrors()) {
                this.form.sale_id = this.sale.id;
                await this.storeCreditNote(this.form);
                this.getSales(this.filters);
                this.closeForm();
            }
        },
        existErrors(){
            let hasErrors = false;

            Object.keys(this.form).forEach(f => {                
                if (this.$refs[f]) {
                    if (!this.form[f]) hasErrors = true
                    this.$refs[f].validate(true);
                }                
            });

            return hasErrors;
        },
        clearErrors() {
            Object.keys(this.form).forEach(f => {
                if (this.$refs[f]) this.$refs[f].reset()                
            });
        }
    }
}
</script>