<template>
    <v-navigation-drawer
        v-model="drawer"          
        width="400"
        app
        temporary
        right
        class="wost-drawer"
    >
        <div class="navigation-head">
            <span>REPORTE SISCONT</span>                         
        </div>
        <div class="pl-3 pt-3 pr-3" style="height: 80vh;">
            <template>
                <!--                
                <v-row>
                    <v-col cols="12">
                        <v-select
                            v-model="filters.type_document"
                            :items="type_documents"
                            label="Documento"
                            outlined
                            clearable
                            hide-details
                        ></v-select>
                    </v-col>
                </v-row>
                -->           
                <v-row>
                    <v-col cols="12">
                        <v-menu
                            v-model="menuFrom"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            min-width="290px"
                        >
                            <template v-slot:activator="{ on }">
                                <v-text-field
                                    v-model="filters.date_from"
                                    label="Desde"
                                    readonly
                                    clearable
                                    v-on="on"
                                    outlined
                                    hide-details                         
                                ></v-text-field>
                            </template>
                            <v-date-picker v-model="filters.date_from" 
                                @input="menuFrom = false" 
                                no-title
                                locale="es">
                            </v-date-picker>
                        </v-menu>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col cols="12">
                        <v-menu
                            v-model="menuUntil"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            min-width="290px"
                        >
                            <template v-slot:activator="{ on }">
                                <v-text-field
                                    v-model="filters.date_until"
                                    label="Hasta"
                                    readonly
                                    clearable
                                    v-on="on"
                                    outlined
                                    hide-details                         
                                ></v-text-field>
                            </template>
                            <v-date-picker v-model="filters.date_until" 
                                @input="menuUntil = false" 
                                no-title
                                locale="es">
                            </v-date-picker>
                        </v-menu>
                    </v-col>
                </v-row>
            </template>
        </div>

        <div class="mt-3 pl-3 pt-3 pr-3">
            <template>
                <v-row>
                    <v-col cols="6">
                        <v-btn block height="40" dark color="red" @click="handleClearFilters">
                            Cancelar
                        </v-btn>
                    </v-col>
                    <v-col cols="6">
                        <v-btn dark color="blue" block height="40" @click="handleSearch">
                            Descargar XLSX
                        </v-btn>
                    </v-col>
                </v-row>
            </template>            
        </div>

    </v-navigation-drawer>
</template>

<script>
import {mapActions, mapState} from 'vuex';

export default {
    name: 'SalesAccounting',
    data () {
        return {
            drawer: false,
            menuFrom: false,
            menuUntil: false,
            type_documents: ['TICKET', 'BOLETA', 'FACTURA'],
            filters: {
                type_document: null,
                date_from: null,
                date_until: null
            }
        }
    },
    computed: {
        ...mapState('authentication', [
            'company_id'
        ]),
        ...mapState('report-sales', [
            'file_sales'
        ])
    },
    methods: {
        ...mapActions('report-sales', [
            'getSales',
            'setFilters',
            'saleAccountingExcel'
        ]),
        show () {
            this.clearData();
            this.drawer = !this.drawer;
        },
        customText(item){
            let text = item.name;
            
            return text;
        },
        customTextCustomer(item){
            let text = item.fullname;
            
            return text;
        },
        closeForm () {
            this.drawer = false;
        },
        clearData () {            
            this.filters.type_document = null;            
            this.filters.date_from = null;
            this.filters.date_until = null;
            this.menuFrom = false;
            this.menuUntil = false;
        },
        handleClearFilters () {
            this.clearData();
            this.closeForm();
        },
        async handleSearch () {
            let filters_data = Object.assign({}, this.filters);
            await this.saleAccountingExcel(filters_data);
            if (this.file_sales) {
                let url = process.env.VUE_APP_API_URL_BASE + '/' + this.file_sales;
                window.open(url);
            }
        }
    }
}
</script>
