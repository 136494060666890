<template>
  <v-navigation-drawer
      v-model="drawer"
      width="400"
      app
      temporary
      right
      class="wost-drawer"
  >
    <div class="navigation-head">
      <span>FILTRAR DATOS DE VENTAS</span>
    </div>
    <div class="pl-3 pt-3 pr-3" style="height: 80vh;">
      <template>
        <v-row>
          <v-col cols="12">
            <v-autocomplete
                v-model="filters.office_id"
                :items="offices"
                label="Sucursal"
                hide-details
                outlined
                :item-text="customText"
                item-value="id"
                clearable
            ></v-autocomplete>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-autocomplete
                v-model="filters.customer_id"
                :items="customers"
                label="Seleccione cliente"
                outlined
                :item-text="customTextCustomer"
                item-value="id"
                clearable
                hide-details
            ></v-autocomplete>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-select
                v-model="filters.type_document"
                :items="type_documents"
                label="Documento"
                outlined
                clearable
                hide-details
            ></v-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-menu
                v-model="menuFrom"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                    v-model="filters.date_from"
                    label="Desde"
                    readonly
                    clearable
                    v-on="on"
                    outlined
                    hide-details
                ></v-text-field>
              </template>
              <v-date-picker v-model="filters.date_from" @input="menuFrom = false" no-title>
              </v-date-picker>
            </v-menu>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12">
            <v-menu
                v-model="menuUntil"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                    v-model="filters.date_until"
                    label="Hasta"
                    readonly
                    clearable
                    v-on="on"
                    outlined
                    hide-details
                ></v-text-field>
              </template>
              <v-date-picker v-model="filters.date_until" @input="menuUntil = false" no-title>
              </v-date-picker>
            </v-menu>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12">
            <v-autocomplete
                v-model="filters.product_id"
                :items="products"
                label="Producto"
                outlined
                item-text="description"
                item-value="product_id"
                clearable
                hide-details="auto"
                :loading="isLoading"
                :search-input.sync="search_product"
                hide-no-data
                hide-selected
                placeholder="Ingrese el producto a buscar"
            ></v-autocomplete>
          </v-col>
        </v-row>

      </template>
    </div>

    <div class="mt-3 pl-3 pt-3 pr-3">
      <template>
        <v-row>
          <v-col cols="6">
            <v-btn block height="40" dark color="red" @click="handleClearFilters">
              <v-icon size="16" class="mr-1">far fa-trash-alt</v-icon>
              Quitar filtros
            </v-btn>
          </v-col>
          <v-col cols="6">
            <v-btn dark color="blue" block height="40" @click="handleSearch">
              <v-icon size="16" class="mr-1">fas fa-search</v-icon>
              Filtrar datos
            </v-btn>
          </v-col>
        </v-row>
      </template>
    </div>

  </v-navigation-drawer>
</template>

<script>
import {mapActions, mapState} from 'vuex';

import InventoryApi from '../../apis/Inventories';

export default {
  name: 'SalesFiltersPaginated',
  data() {
    return {
      drawer: false,
      menuFrom: false,
      menuUntil: false,
      type_documents: ['TICKET', 'BOLETA', 'FACTURA'],
      filters: {
        company_id: null,
        sede_id: null,
        office_id: null,
        type_document: null,
        modality: null,
        date_from: this.$moment().subtract(7, 'days').format('YYYY-MM-DD'),
        date_until: this.$moment().format('YYYY-MM-DD'),
        customer_id: null,
        product_id: null
      },
      isLoading: false,
      search_product: null,
      products: []
    }
  },
  computed: {
    ...mapState('authentication', [
      'company_id',
      'office_id'
    ]),
    ...mapState('customers', [
      'customers'
    ]),
    ...mapState('sedes', [
      'sedes'
    ]),
    ...mapState('offices', [
      'offices'
    ]),
    ...mapState('saleoptions', [
      'sale_options'
    ])
  },
  watch: {
    async search_product(value) {
      if (value == null || value == undefined || value.length == 0) {
        return;
      }

      if (this.products.length > 0) {
        return;
      }

      if (this.isLoading) return

      this.isLoading = true

      let filters = {
        description: value
      }
      InventoryApi.getProductsFromOffice(filters)
          .then(response => {
            this.products = response.data;
          })
          .catch(error => {
            console.log(error);
          })
          .finally(() => (this.isLoading = false));
    },
  },
  methods: {
    ...mapActions('offices', [
      'getOffices',
      'run_set_offices'
    ]),
    ...mapActions('report-sales', [
      'getSales',
      'setFilters',
      'changeFilterPaginated'
    ]),
    show() {
      this.filters.company_id = this.company_id;
      this.drawer = !this.drawer;
    },
    customText(item) {
      let text = item.name;

      return text;
    },
    customTextCustomer(item) {
      let text = item.fullname;

      return text;
    },
    closeForm() {
      this.drawer = false;
    },
    clearData() {
      this.filters.sede_id = null;
      this.filters.office_id = this.office_id;
      this.filters.type_document = null;
      this.filters.modality = null;
      this.filters.date_from = this.$moment().subtract(7, 'days').format('YYYY-MM-DD');
      this.filters.date_until = this.$moment().format('YYYY-MM-DD');
      this.filters.customer_id = null;
      this.filters.product_id = null;
      this.menuFrom = false;
      this.menuUntil = false;
      this.changeFilterPaginated({...this.filters, paginated: true, itemsPerPage: 10})
      this.setFilters(null);
    },
    handleClearFilters() {
      this.clearData();
      this.getSales({...this.filters,paginated:true,itemsPerPage:10});
    },
    async handleSearch() {
      let filters_data = Object.assign({}, this.filters);
      this.setFilters(filters_data);
      this.changeFilterPaginated({...this.filters, paginated: true, itemsPerPage: 10})
      await this.getSales({...this.filters,paginated:true,itemsPerPage:10});
      this.closeForm();
    }
  },
  created() {
    this.filters.office_id = this.office_id;
  }
}
</script>
