<template>
  <v-container fluid>
    <template>
      <v-row>
        <v-col cols="6" class="pb-1 pt-0">
          <v-breadcrumbs :items="itemsBreadcrumbs" class="pl-0 pt-0 pb-1">
            <template v-slot:item="{ item }">
              <v-breadcrumbs-item
                  :href="item.href"
                  :disabled="item.disabled"
              >
                {{ item.text.toUpperCase() }}
              </v-breadcrumbs-item>
            </template>
          </v-breadcrumbs>
        </v-col>
        <v-col cols="6" class="pb-1 pt-0 h-100">
          <office-login></office-login>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="10" class="pt-1">
          <span class="headline font-weight-medium">Ventas</span>
        </v-col>
        <v-col cols="2" class="pt-1">
          <v-btn dark color="#023145" width="140" class="float-right" @click="handleShowFilters">
            Filtros
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" class="pt-1">
          <v-btn
              dark
              color="success"
              width="140"
              class="float-right"
              :loading="is_download"
              @click="handleDownloadReport">
            Descargar XLS
          </v-btn>
          <!--
          <v-btn
              dark
              color="blue"
              width="140"
              class="float-right mr-2"
              @click="handleDownloadReportAccounting">
              Reporte SISCONT
          </v-btn>
          -->
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" class="pt-1">
          <v-card>
            <v-data-table
                :headers="headers"
                :items="sales"
                item-key="id"
                :items-per-page="10"
                class="elevation-1 w-table"
                height="57vh"
                fixed-header :loading="loading"
                :options.sync="options"
                :server-items-length="paginated.total"
                loading-text="Loading... Please wait">
              <template v-slot:top>
                                <span class="ml-3 pt-3 pb-3 subtitle-1 font-weight-bold w-title-table">
                                    Todas las ventas
                                </span>
                <v-divider></v-divider>
              </template>

              <template v-slot:item.office_id="{item}">
                <span class="label-office">{{ item.office.name }}</span>
              </template>

              <template v-slot:item.registration_date="{item}">
                <span>{{ item.registration_date | moment('DD/MM/YYYY') }}</span>
              </template>

              <template v-slot:item.hour="{item}">
                <span>{{ item.registration_date | moment('hh:mm') }}</span>
              </template>

              <template v-slot:item.type_document="{item}">
                                <span v-if="item.canceled === true" class="sale-cancel">
                                    {{ item.type_document }}
                                </span>
                <span v-else>
                                    {{ item.type_document }}
                                </span>
              </template>

              <template v-slot:item.serie="{item}">
                                <span v-if="item.canceled === true" class="sale-cancel">
                                    {{ item.serie }}
                                </span>
                <span v-else>
                                    {{ item.serie }}
                                </span>
              </template>

              <template v-slot:item.correlative="{item}">
                                <span v-if="item.canceled === true" class="sale-cancel">
                                    {{ item.correlative }}
                                </span>
                <span v-else>
                                    {{ item.correlative }}
                                </span>
              </template>

              <template v-slot:item.customer_id="{item}">
                                <span v-if="item.customer_id">
                                    {{ item.customer.fullname }}
                                </span>
                <span v-else>
                                    Público general
                                </span>
              </template>

              <template v-slot:item.document="{item}">
                                <span v-if="item.customer_id">
                                    {{ item.customer.document }}
                                </span>
              </template>

              <!--
              <template v-slot:item.discount="{item}">
                  <span>{{ item.discount | currency('S/') }}</span>
              </template>

              <template v-slot:item.subtotal="{item}">
                  <span>{{ item.subtotal | currency('S/') }}</span>
              </template>

              <template v-slot:item.igv="{item}">
                  <span>{{ item.igv | currency('S/') }}</span>
              </template>
              -->

              <template v-slot:item.total="{item}">
                <span>{{ item.total | currency('S/') }}</span>
              </template>

              <template v-slot:item.detail="{item}">
                <v-btn v-if="item.canceled === false"
                       icon
                       small
                       dark
                       color="blue"
                       @click="handleViewDetail(item)">
                  <v-icon size="14">fas fa-bars</v-icon>
                </v-btn>
                <v-btn v-if="item.canceled === false"
                       icon
                       small
                       dark
                       color="red"
                       @click="handleCancelInvoice(item)">
                  <v-icon size="14">far fa-times-circle</v-icon>
                </v-btn>

                <span v-if="item.canceled === true" class="sale-cancel">
                                    ANULADO
                                </span>
              </template>

            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
    </template>

    <sales-filters ref="salesFilters"></sales-filters>
    <cancel-invoice ref="cancelInvoice"></cancel-invoice>
    <sales-accounting ref="salesAccounting"></sales-accounting>
  </v-container>
</template>

<script>
import {mapActions, mapState} from 'vuex';
import router from '@/router/index';
import SalesFilters from '../../../components/reports/SalesFiltersPaginated';
import CancelInvoice from '../../../components/reports/CancelInvoice';
import OfficeLogin from '../../../components/auth/OfficeLogin';
import SalesAccounting from '../../../components/reports/SalesAccounting';

export default {
  name: 'ReportSales',
  components: {
    SalesFilters,
    CancelInvoice,
    OfficeLogin,
    SalesAccounting
  },
  data() {
    return {
      itemsBreadcrumbs: [
        {
          text: 'Reportes',
          disabled: true,
          href: '',
        }
      ],
      headers: [
        {text: 'LUGAR', value: 'office_id', sortable: false},
        {text: 'FECHA', value: 'registration_date', sortable: false},
        {text: 'HORA', value: 'hour', sortable: false},
        {text: 'DOCUMENTO', value: 'type_document', sortable: false},
        {text: 'SERIE', value: 'serie', sortable: false},
        {text: 'N° DOC.', value: 'correlative', sortable: false},
        {text: 'CLIENTE', value: 'customer_id', sortable: false},
        {text: 'RUC/DNI.', value: 'document', sortable: false},
        {text: 'MÉTODO DE PAGO', value: 'payment_method', sortable: false},
        {text: 'TOTAL', value: 'total', sortable: false, align: 'end'},
        {text: '', value: 'detail', sortable: false, align: 'end'}
      ],
      is_download: false,
      date_init: this.$moment().subtract(7, 'days').format('YYYY-MM-DD'),
      date_end: this.$moment().format('YYYY-MM-DD'),
      loading: false,
      dataTable: {page: 1},
      options: {},
      pageSelected: 1,
      perPage: 10,
      search_product: null
    }
  },
  computed: {
    ...mapState('authentication', [
      'company_id',
      'office_id'
    ]),
    ...mapState('report-sales', [
      'sales',
      'filters',
      'sale',
      'file_sales',
      'paginated',
      'filtersPaginated'
    ])
  },
  watch: {
    options: {
      handler() {
        console.log(this.options)
        this.perPage = this.options.itemsPerPage
        this.pageSelected = this.options.page
        this.loading = true
        const search = this.search_product
        console.log(this.filtersPaginated)
        if (this.filtersPaginated.company_id!=null || this.filtersPaginated.sede_id!=null ||
            this.filtersPaginated.office_id!=null || this.filtersPaginated.type_document!=null ||
            this.filtersPaginated.modality!=null || this.filtersPaginated.date_from!=null ||
            this.filtersPaginated.date_until!=null || this.filtersPaginated.customer_id!=null||
            this.filtersPaginated.product_id!=null
        ){
          this.getSales({
            company_id: this.filtersPaginated.company_id,
            office_id: this.filtersPaginated.office_id,
            modality: this.filtersPaginated.modality,
            date_from: this.filtersPaginated.date_from,
            date_until: this.filtersPaginated.date_until,
            customer_id: this.filtersPaginated.customer_id,
            product_id: this.filtersPaginated.product_id,
            type_document: this.filtersPaginated.type_document,
            sede_id: this.filtersPaginated.sede_id,
            paginated: true, page:this.pageSelected,
            itemsPerPage: this.perPage,
          }).then(res => this.loading = false)
              .catch(err => this.loading = false);
        }
        else{
          if (search != null && search.trim().length > 0) {
            this.perPage = -1
            this.getSales({
              company_id: this.company_id,
              office_id: this.office_id,
              paginated: true, page: this.pageSelected,
              itemsPerPage: this.perPage,
              name: search,
              date_from: this.date_from,
              date_until: this.date_until,
            }).then(res => this.loading = false)
                .catch(err => this.loading = false);
          } else {
            this.getSales({
              company_id: this.company_id,
              office_id: this.office_id,
              paginated: true,
              page: this.pageSelected,
              itemsPerPage: this.perPage,
              date_from: this.date_from,
              date_until: this.date_until,
            }).then(res => this.loading = false)
                .catch(err => this.loading = false);
          }
        }

      },
      deep: true,
    }
  },
  mounted() {
    this.changeFilterPaginated({
      company_id: null,
      sede_id: null,
      office_id: null,
      type_document: null,
      modality: null,
      date_from: null,
      date_until: null,
      customer_id: null,
      product_id: null
    })
  },
  methods: {
    ...mapActions('customers', [
      'getCustomers'
    ]),
    ...mapActions('offices', [
      'getOffices'
    ]),
    ...mapActions('sedes', [
      'getSedes'
    ]),
    ...mapActions('saleoptions', [
      'getSalesOptions'
    ]),
    ...mapActions('report-sales', [
      'getSales',
      'setFilters',
      'selectSale',
      'getSaleDetail',
      'salesExcel',
      'emptyFileSales',
      'setSales',
      'changeFilterPaginated'

    ]),
    handleShowFilters() {
      this.$refs.salesFilters.show();
    },
    handleViewDetail(sale) {
      this.selectSale(sale);
      router.push('/app/reportes/detalle-ventas');
    },
    async handleDownloadReport() {
      this.is_download = true;
      this.emptyFileSales();
      let filters_data = Object.assign({}, this.filters);
      filters_data.company_id = this.company_id;
      await this.salesExcel(filters_data);
      this.is_download = false;
      if (this.file_sales) {
        let url = process.env.VUE_APP_API_URL_BASE + '/' + this.file_sales;
        window.open(url);
      }
    },
    handleCancelInvoice(sale) {
      this.$refs.cancelInvoice.show(sale);
    },
    handleDownloadReportAccounting() {
      this.$refs.salesAccounting.show();
    }
  },
  created() {
    this.getCustomers();
    this.setFilters({
      company_id: this.company_id,
      office_id: this.office_id,
      date_from: this.date_init,
      date_until: this.date_end,
      paginated: true,
      // itemsPerPage:10
    });
    // this.loading=true
    // this.getSales(this.filters).then(res=>this.loading=false)
    //     .catch(err=>this.loading=false);
    // this.getOffices({
    //     company_id: this.company_id
    // });
    this.getSalesOptions();
  },
  destroyed() {
    this.setSales([]);
  }
}
</script>

<style scoped>
.label-office {
  color: #2196F3;
}

.sale-cancel {
  color: #F44336;
  font-weight: 600;
}
</style>